import React, {
  useContext,
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from "react";
import useAxios from "axios-hooks";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import config from "../config";

const UserSessionContext = createContext();

export function UserSessionProvider(props) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [twoFactorUserEmail, setTwoFactorUserEmail] = useState(null);

  const { TOKEN_KEY } = config;

  // Login request
  const [
    {
      data: loginData,
      loading: loadingLogin,
      error: errorLogin,
      response: resLogin,
    },
    executeLogin,
  ] = useAxios({ url: "/login", method: "POST" }, { manual: true });

  // Logout request
  const [
    { data: logoutData, loading: loadingLogout, error: errorLogout },
    executeLogout,
  ] = useAxios({ url: "/logout", method: "DELETE" }, { manual: true });

  // Request User Session info
  const [
    { data: userData, loading: loadingUser, error: errorUser },
    executeUser,
  ] = useAxios({ url: "/users/current" }, { manual: true });

  useEffect(() => {
    resLogin &&
      localStorage.setItem(
        TOKEN_KEY,
        resLogin.headers.get("Authorization") || ""
      );
    // eslint-disable-next-line
  }, [resLogin]);

  useEffect(() => {
    // validate 2fa required
    if (loginData) {
      switch (loginData.code) {
        case 200: // autentication ok
          navigate("/");
          break;
        case 201: // 2fa requested
          navigate("account/two-factor-authentication");
          break;
        case 401: // invalid 2fa
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [loginData]);

  useEffect(() => {
    if (logoutData) {
      localStorage.removeItem(TOKEN_KEY);
    }
    // eslint-disable-next-line
  }, [logoutData]);

  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token && !currentUser) {
      executeUser();
    }
    // eslint-disable-next-line
  }, []);

  const login = useCallback(
    (values) => {
      setTwoFactorUserEmail(values.email);
      //make the request
      executeLogin({ data: { user: values } });
    },
    [executeLogin]
  );

  const twoFactorAuth = useCallback(
    (values) => {
      //make the request
      executeLogin({
        data: { user: { ...values, email: twoFactorUserEmail } },
      });
    },
    [executeLogin, twoFactorUserEmail]
  );

  const isUserAuthenticated = useCallback(() => {
    const authToken = localStorage.getItem(TOKEN_KEY) || null;
    return !isEmpty(authToken);
    // eslint-disable-next-line
  }, []);

  const logout = useCallback(async () => {
    executeLogout({ data: { currentUser } });
    // eslint-disable-next-line
  }, [executeLogout]);

  const value = useMemo(
    () => ({
      twoFactorAuth,
      loadingLogin,
      errorLogin,
      login,
      isUserAuthenticated,
      logout,
      loadingLogout,
      errorLogout,
      loadingUser,
      errorUser,
      currentUser,
      twoFactorUserEmail,
      executeUser,
    }),
    [
      twoFactorAuth,
      loadingLogin,
      errorLogin,
      login,
      isUserAuthenticated,
      logout,
      loadingLogout,
      errorLogout,
      loadingUser,
      errorUser,
      currentUser,
      twoFactorUserEmail,
      executeUser,
    ]
  );
  return (
    <UserSessionContext.Provider
      value={value}
      {...props}
    ></UserSessionContext.Provider>
  );
}

export function useUserSessionContext() {
  const context = useContext(UserSessionContext);
  if (!context) {
    throw new Error("Something went wrong with user session context");
  }
  return context;
}
